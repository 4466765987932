import $ from "jquery";
import {removeLocal} from "../outils/OutilsDivers";


$(document).ready(function () {
   // on efface le stockage local
    let keysToRemove=['versions_index','boutons_index']
    keysToRemove.forEach(function (item){
        removeLocal(item+'_barre_scroll')

    })

    keysToRemove=['versions_index']
    keysToRemove.forEach(function (item){
        removeLocal(item+'_versionOpen')
        removeLocal(item+'_filtreActif')
        removeLocal(item+'_itemActif')

    })

    removeLocal("barre_aux_item");
    removeLocal("barre_item");
    removeLocal("barre_scroll");
    removeLocal("barre_aux_scroll");

    removeLocal("tagTypeActif");
    removeLocal("parametreTypeActif");

})







